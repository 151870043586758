export default {
  home: {
    minute: "分钟",
    price_info:
      "首{mianfei}分钟内免费, 日封顶{currencyName}{fengding}, 计费满{currencyName}{yajin}自动扣取押金。",
    feature_1: "四种充电方式，适配所有手机",
    feature_2: "全国通借通还",
    feature_3: "特别为无线充电设计",
    tips_1: "退还充电宝后，您的{currencyName}{yajin}押金将退还给您。",
    // tips_1: '余额不足，需充值满{currencyName}{yajin}押金才可用',
    tips_2: "充值押金{currencyName}{yajin}即可租用，用完即可退",
    tips_3: "当前余额{currencyName}{myyue}，可以直接租借",
    submit: "立即充值",
    borrow: "立即租借",
    msg_fail_title: "提示",
    msg_fail_content: "租借失败，请重新尝试",
    msg_rentcount: "你当前有{zujieNum}个订单未结束",
    goborrow: "去租借!!!",
    nearby: "查看附近网点",
    preauth: "预授权金额",
    scan: '扫码'
  },
  login: {
    code_title: "短信验证",
    phone_number: "手机号码",
    verification_code: "请输入短信验证码",
    captcha_code: "验证码",
    send_code: "获取验证码",
    login: "登录",
    agree: "点击登录，表示已阅读并同意",
    readme: "使用条款",
    msg_fail_login: "登录失败",
    msg_success_login: "登录成功",
    msg_fail_user: "获取用户信息出错，是否重试？",
    sign_in_with: "第三方登录",
    no_sms: "没收到短信",
    retry: "重新发送",
    emaillogin: '邮箱登录',
    logintipphone: 'What\'s your phone number?',
    sunlogintipphone: 'We\'ll text a code to verify your phone',
    logintipemail: 'What\'s your email?',
    sunlogintipemail: 'We\'ll text a code to verify your email'
  },
  confirm: {
    title: "确认付款",
    paynow: "立即付款",
    SMSConfirm: "请根据手机收到的短信提示付款后点击确认按钮继续。",
    pay_label: "需支付",
    auth_label: "预授权",
    pay_type: "支付方式",
    submit: "确认支付",
    msg_loading: "加载中...",
    msg_fail_title: "提示",
    msg_fail_payment: "发起押金支付失败",
    msg_fail_order: "创建租借订单失败",
    msg_fail_support: "当前环境不支持",
    msg_fail_confirm: "确定",
    msg_fail_retry: "重试",
    msg_fail_cancel: "取消"
  },
  mine: {
    title: "个人中心",
    wallet: "我的钱包",
    balance: "我的余额",
    deposit: "押金",
    wallet_in: "充值",
    wallet_out: "提现",
    wallet_log: "充值提现",
    order: "租借订单",
    coupon: "优惠券",
    login_tips: "请先登录",
    msg_fail_title: "提示",
    msg_fail_update: "修改失败，请重新尝试",
    msg_loading: "加载中...",
    msg_fail_user: "获取用户信息出错，是否重试？",
    help: "帮助",
    setting: "设置",
    logout: "退出",
    buy: "购买设备",
    menutip1: 'LotusCharge您的场地',
    menutip2: '成为合作伙伴并在您的场地获得LotusCharge充电站'
  },
  msg: {
    no_phone: "手机号必填",
    no_email: '邮箱必填',
    loading: "加载中...",
    title: "提示",
    success: "操作成功",
    error: "未知异常，请联系管理员",
    roleerror: "权限不足，请咨询管理员",
    neterror: "网络连接失败，请稍后再试",
    confirm: "确认",
    download: "下载",
    retry: "重试",
    cancel: "取消",
    title_fail: "错误",
    vieworder: "查看订单",
    success_payment: "充值成功",
    success_buy: "购买成功",
    fail_payment: "付款失败",
    keyword: "关键词",
    ispayorder: "请确认是否已支付？",
    unpay: "未支付",
    yespay: "已支付",
    notopen: "暂未开放",
    appoffline: 'APP暂时还未上线，请等待'
  },
  result: {
    title: "租借成功",
    tips: "你的充电宝准备好了，抓住它，享受你的一天，没有压力！",
    gotit: "知道了",
    price_plan: "计费规则",
    msg_success: "租借成功",
    msg_tips_loading: "正在弹出充电宝，请耐心等待...",
    msg_result_loading: "正在确认支付结果...",
    msg_fail_confirm: "确定",
    btn_usage: "使用说明",
    btn_return: "归还说明",
    deposit: "押金",
    msg_fail_content: "请求失败，请重新尝试",
    price_label: "/小时",
    price_info:
      "{mianfei}分钟内免费，日封顶{fengding}{ccurrency}，计费满{yajin}{ccurrency}自动扣取押金",
    tips_1: '请按图取走<span class="number">{number}号</span>位置充电宝',
    tips_2:
      '归还请收好按箭头<span class="highlight">【线朝下方】</span>插入机柜并锁',
    msg_note_usage: "充电宝配有三种充电线，请按需使用",
    msg_note_return: "请把充电线插回相应位置后，把充电宝插入机柜的卡槽中",
    return_info:
      "归还时，整理好充电线，将充电宝芯片端插入机柜空缺槽口至底部被固定即可。"
  },
  result_msg: {
    loading: "加载中...",
    refresh: "点击刷新"
  },
  order: {
    title: "租借订单",
    in_use: "正在使用",
    ended: "已结束",
    msg_error: "加载失败"
  },
  order_item: {
    order_number: "订单号",
    venue_rented: "租借地点",
    time_rented: "租借时间",
    venue_returned: "归还地点",
    time_returned: "归还时间",
    charge_standard: "收费标准",
    charge_standard_info: "每小时{pJifei}{pCurrency}(前{pMian}分钟免费)",
    rent_occurred: "租借费用",
    returned: "已归还",
    unreturned: "未归还",
    type_0: "未租借",
    type_1: "租借中",
    type_2: "已撤销",
    type_3: "已归还",
    type_4: "有疑问",
    type_5: "超时归还",
    min: "分",
    payment: "付款"
  },
  setting: {
    title: "设置",
    lang: "选择语言"
  },
  help: {
    title: "帮助"
  },
  wallet_in: {
    title: "余额充值",
    price_label: "充值金额",
    price_placeholder: "请输入充值金额",
    balance: "当前余额",
    price_auto: "自动充值",
    price_tips: "充值金额申请提现后1~5天到账",
    submit: "确定充值",
    loading: "加载中...",
    paying: "跳转到付款页...",
    msg_title: "提示",
    msg_fail_order: "创建充值订单失败",
    msg_fail_payment: "发起充值支付失败",
    msg_fail_retry: "重试"
  },
  wallet_out: {
    title: "余额提现",
    price_label: "提现金额",
    price_placeholder: "请输入提现金额",
    balance: "当前余额",
    balance_all: "全部提取",
    deposit: "押金",
    deposit_tips: "目前有租借中的订单，押金Ksh{pyajin}暂不可退",
    submit: "立即提现",
    apply: "申请提现",
    confirm: "确认",
    loading: "加载中...",
    msg_fail_price: "提现金额不正确",
    msg_fail_balance: "提现金额不能大于当前余额",
    msg_fail_submit: "请填写完整信息",
    msg_title: "错误",
    msg_success_content: "申请提现成功",
    confirmtitle: "提示",
    confirmcontent: "当前钱包不支持提现，请备注您的联系方式后由人工退款",
    payee_name: "姓名",
    payee_bankcard: "银行卡号",
    payee_phone: "电话",
    cancelText: "取消",
    confirmText: "再次申请",
    noPhone: "请输入手机号"
  },
  map: {
    title: "附近设备",
    result_msg: "附近未找到相关设备",
    msg_title: "错误",
    no_support: "抱歉！您的浏览器不支持定位",
    my_position: "我的位置",
    distance: "距你",
    shop_time: "营业时间",
    free: "可借",
    return: "可还",
    shop_title: "商家详情",
    address: "地址",
    go_here: "去这里",
    '在线': '在线',
    '离线': '离线'
  }
};
